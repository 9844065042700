import * as React from "react";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo";
import {graphql} from "gatsby";
import * as styles from "./house.module.scss";
import Gallery from "@browniebroke/gatsby-image-gallery";

const House3Page = ({data}) => {
  // const flats = data.api.flats.filter((flat) => flat.id.startsWith("3"))

  const images = data.allFile.edges.map(({node}, index) => ({
    ...node.childImageSharp,
    caption: `Obrázek ${index + 1}`
  }));

  const lightboxOptions = {
    imageLoadErrorMessage: 'Nelze načíst obrázek',
    nextLabel: 'Další',
    prevLabel: 'Předchozí',
    zoomInLabel: 'Přiblížit',
    zoomOutLabel: 'Oddálit',
    closeLabel: 'Zavřít',
  }

  return (
    <Layout contactInfo={data.api.contactInfo}>
      <Seo title="Dům III" />

      <section className={styles.wrapper}>
        <h1 className={styles.title}>Dům III</h1>

        <h4 className={styles.message}>Nabídku bytů pro Vás připravujeme</h4>
        {images?.length > 0 && (
          <div className={styles.galleryWrapper}>
            <h4>Galerie</h4>
            <Gallery images={images} lightboxOptions={lightboxOptions} imgClass={styles.galleryImage} />
          </div>
        )}
      </section>
    </Layout>
  )
}

export const query = graphql`
    query House3PageQuery {
        api {
            contactInfo:contactSingleton {
                email
                phone
                name
            }
        }
        allFile(filter: {relativePath: {glob: "previews/*"}}) {
            edges {
                node {
                    childImageSharp {
                        thumb: gatsbyImageData(
                            width: 270
                            height: 270
                            placeholder: BLURRED
                        )
                        full: gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;

export default House3Page;
